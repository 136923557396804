import React from 'react'
import { Accordion, Container } from "react-bootstrap";
import './AccordianMain.css'

export default function AccordianMain() {
    return (
        <section className="p-5 bg-dark text-white border border-dark">
            <Container>
                <h1 className="text-center">Our objectives are to</h1>

                <div className="row">
                    <div className="col-md-6">

                        <Accordion defaultActiveKey="4" flush className="pb-1">
                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header className="">Protect</Accordion.Header>
                                <Accordion.Body className='text-white '>
                                    Protect and support women and children who have suffered, are suffering or who are in danger of suffering domestic violence and abuse.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="4" flush className="pb-1">
                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header>Guide</Accordion.Header>
                                <Accordion.Body className='text-white'>
                                    Guide and support towards the prevention of domestic violence and abuse and guide towards options available to them.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="4" flush className="pb-1">
                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header>Educate and Train</Accordion.Header>
                                <Accordion.Body className='text-white'>
                                    Educate and train women and children to empower them to advance in life. Also offering training and education programs if needed.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                    <div className="col-md-6">
                        <Accordion defaultActiveKey="4" flush className="pb-1">
                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header className="">Support</Accordion.Header>
                                <Accordion.Body className='text-white'>
                                    Offer guidance, support and practical help.
                                </Accordion.Body >
                            </Accordion.Item>
                        </Accordion>
                        <Accordion flush className="pb-1">
                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header>Promote Health</Accordion.Header>
                                <Accordion.Body className='text-white'>
                                    Promote the mental and physical health of women and children affected by domestic violence and abuse.
                                </Accordion.Body >
                            </Accordion.Item>
                        </Accordion>
                        <Accordion flush>

                            <Accordion.Item eventKey="0" className="bg-dark">
                                <Accordion.Header>Raise Awareness</Accordion.Header>
                                <Accordion.Body className='text-white'>
                                    Raise awareness of, inform and educate the public on all aspects of domestic violence and abuse, its causes, consequences and prevention.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </section>
    )
}
