import React from 'react'
import { Container } from "react-bootstrap";

export default function Strapline() {
    return (
        <>
            <Container>
                <div className="justify-content-center">
                    <div className="align-self-center text-center ">
                        <h1 className="pt-0">Aid & Education for Women</h1>
                        <h5 className='pb-5'>At Shanti, we want to Empower Women to have an Independent and Confident life.We will take time to listen to you, guide you and support you through your journey.</h5>
                        <h1 className='pb-5 text-primary'>Shanti means <i>Peace & Calm</i></h1>
                    </div>
                </div>
            </Container>
        </>
    )
}





