import React from 'react'
import { Container } from "react-bootstrap";

export default function SocialMedia() {
    return (
        <Container>
            <section id="home-icons">
                <div className="row">
                    <div className="col-md-4 mb-1 text-center"></div>
                    <div className="col-md-1 mb-1 text-center">
                        <a className="text-dark" target="_blank" rel="noreferrer" href="https://twitter.com/ShantiAid"><i role="button" aria-label="button" className="fab fa-twitter-square fa-4x mb-2"></i></a>

                    </div>
                    <div className="col-md-1 mb-1 text-center">
                        <a className="text-dark" target="_blank" rel="noreferrer" href="https://www.facebook.com/ShantiAidandEducation"><i role="button" aria-label="button" className="fab fa-facebook-square fa-4x mb-2"></i></a>
                    </div>
                    <div className="col-md-1 mb-1 text-center">
                        <a className="text-dark" target="_blank" rel="noreferrer" href="https://www.instagram.com/shantiaid/"><i role="button" aria-label="button" className="fab fa-instagram fa-4x mb-2"></i></a>
                    </div>
                    <div className="col-md-1 mb-1 text-center">
                        <a className="text-dark" target="_blank" rel="noreferrer" href="mailto:admin@shanti-aid.org"><i role="button" aria-label="button" className="fas fa-envelope-square fa-4x mb-2"></i></a>
                    </div>
                    <div className="col-md-3 mb-1 text-center"></div>
                </div>
            </section>
        </Container>
    )
}
