import React from 'react'
import { Container } from "react-bootstrap";

export default function GoFundme() {
    return (
        <Container>
            <section id="info">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center">
                        <iframe src="https://www.gofundme.com/f/shanti-aid-education-for-women/widget/medium" frameBorder="0" height="320" title="Donation">
                        </iframe>
                    </div>
                    <div className="col-md-4"></div>
                </div>
            </section>
        </Container>
    )
}
