import React from 'react'
import { Container } from "react-bootstrap";

export default function Approach() {
    return (
        <Container>
            <section className="pt-5 pb-4">
                <h1 className="text-center">Our Approach is to</h1>
                <div className="row">
                    <div className="col-md-4 mb-0 text-center">
                        <h3>Assess</h3>
                        <p>From information provided by our users on our enquiry form we identify the services required. </p>
                    </div>
                    <div className="col-md-4 mb-0 text-center">
                        <h3>Tailor</h3>
                        <p>Access to services is tailored to our users and is fed by the assessment process.</p>
                    </div>
                    <div className="col-md-4 mb-0 text-center">
                        <h3>Signpost</h3>
                        <p>When necessary, users are signposted.</p>
                    </div>
                </div>
            </section>
        </Container>
    )
}
