import React from 'react'
import { Parallax } from 'react-parallax';

export default function ParallaxMain() {
    const paralaxInlineStyle = {
        // background: '#fff',
        color: 'white',
        left: '50%',
        top: '50%',
        position: 'absolute',
        padding: '20px',
        transform: 'translate(-50%, -50%)'
    }
    return (
        <Parallax bgImage="strong-women.jpg" strength={500}>

            <div style={{ height: 500, backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
                <div style={paralaxInlineStyle}>
                    <h1 className="display-4 mr-2">A strong woman stands up for herself</h1>
                    <h1 className="display-4 mr-2">a stronger women stands up for everyone else</h1>
                </div>
            </div>
        </Parallax>)
}
