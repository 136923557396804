import { Link } from 'gatsby'
import React from 'react'
import { Button, Container } from 'react-bootstrap'

export default function NewsButton() {
    return (
        <Container className='text-center'>
            <p className='pt-5 pb-5'>
            <Link to="/news">
                <Button className='py-6' size="lg" variant="danger">Shanti Aid News</Button>
            </Link>
            </p>
        </Container>
    )
}
