// import React from "react"
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { graphql } from 'gatsby'
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import BannerMain from '../components/BannerMain';
import LotteryCommunity from '../components/LotteryCommunity';
import CarouselMain from '../components/CarouselMain';
import FundRaising from '../components/FundRaising';
// import ContactDetails from '../components/ContactDetails';
import SocialMedia from '../components/SocialMedia';
import GoFundme from '../components/GoFundme';
import ParallaxMain from '../components/ParallaxMain';
import AccordianMain from '../components/AccordianMain';
import Approach from '../components/Approach';
import NewsButton from '../components/NewsButton';
import Strapline from '../components/Strapline';
import Funding from '../components/Funding';

export default function Home({ data }) {
  return (
    <div>
      <Layout title="home">
        <BannerMain logoImage={data.logoimage.childImageSharp.gatsbyImageData} />
        <LotteryCommunity lotteryImage={data.lottery.childImageSharp.gatsbyImageData} />
        <CarouselMain
          choiceImage={data.choiceimage.childImageSharp.gatsbyImageData}
          strongImage={data.strongimage.childImageSharp.gatsbyImageData}
          victImage={data.victimage.childImageSharp.gatsbyImageData}
        />
        <FundRaising
          eflogo={data.eflogosm.childImageSharp.gatsbyImageData}
          gofundme={data.gofundme.childImageSharp.gatsbyImageData}
          smile={data.smile.childImageSharp.gatsbyImageData}
        />
        
        <GoFundme />
        <Funding />
        <SocialMedia />
        <Container>
          <section id="home-icons">
            <div className="container">
              <div className="row">
                <div className="col-md-1 mb-1 text-center"></div>
                <div className="col-md-10 mb-1 text-center">
                  {/* <p>To find out more please feel free to call or email us.</p> */}
                  {/* <p>We would be more than happy to talk to you about what we could offer you or someone you know.</p> */}
                </div>
                <div className="col-md-1 mb-1 text-center"></div>
              </div>
            </div>
          </section>
        </Container>
        <NewsButton />
        <ParallaxMain />
        <AccordianMain />
        <Approach />
        <Strapline />
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    eflogo: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    },
    eflogosm: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    },
    gofundme: file(relativePath: { eq: "gofundme.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },    
    smile: file(relativePath: { eq: "amazon-smile-uk.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },  
    logoimage: file(relativePath: { eq: "shanti_aid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    },
    handimage: file(relativePath: { eq: "open-hand.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }      

    negotiate: file(relativePath: { eq: "negotiate.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }      
    ,
    strongimage: file(relativePath: { eq: "strong2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }, 
    lottery: file(relativePath: { eq: "national_lottery.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },
    sunsetimage: file(relativePath: { eq: "sunset.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },
    choiceimage: file(relativePath: { eq: "choice.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },      
    victimage: file(relativePath: { eq: "vict.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },        
  }
`